<template>
  <div id="ContactComponent" class="mt-4">
      <form id="Contact" data-netlify="true" method="POST" name="Contact">
        <input type="hidden" name="form-name" value="Contact" />
        <div class="row">
          <div class="col-md-6 col-sm-12 mb-3">
              <label for="fullname">Name*</label>
              <input name="fullname" type="text" class="form-control" id="fullname" placeholder="John Appleseed" aria-label="Input for Legal Full Name" required>
          </div>
          <div class="col-md-6 col-sm-12 mb-3">
              <label for="gradyear">Graduation Year</label>
              <input name="gradyear" type="text" class="form-control" id="gradyear" aria-label="Input for Graduation Year" placeholder="2021">
          </div>
        </div>
        <div class="row">
            <div class="col-md-6 col-sm-12 mb-3">
                <label for="emailadd">Email*</label>
                <input name="emailadd" type="email" class="form-control" id="emailadd" placeholder="John@appleseed.com" aria-label="Input for Email Address" required>
            </div>
            <div class="col-md-6 col-sm-12 mb-3">
                <label for="phone">Phone (Text Messaging)*</label>
                <input name="phone" type="text" class="form-control" id="phone" placeholder="123-456-7890" aria-label="Input for Phone Number for SMS Alerts and Reminders" required>
            </div>
        </div>
        <div class="form-group mb-3">
            <label for="overview">Message</label>
            <textarea name="overview" id="overview" class="form-control" placeholder="What would you like to say?"  aria-label="Textbox for your message to the staff"></textarea>
        </div> 
        <button type="submit" class="btn btn-dark d-block px-4" aria-label="Submit button">Submit</button>
      </form>
  </div>
</template>

<script>
export default {
  name: 'ContactForm'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#contact{
    margin: auto;
}

</style>
