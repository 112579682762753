<template>
  <div class="container my-4">
      <h1>Send us a message</h1>
      <p class="lead">Thank you for contacting us, we appreciate your interest in the Wellsville High School Soccer team and will respond as soon as we can. </p>
      <br>
      <ContactForm />

      <Spacer />
  </div>
</template>

<script>
// @ is an alias to /src
import ContactForm from '@/components/Contact-Form.vue';
import Spacer from '@/components/Spacer.vue';

export default {
  name: "Contact",
  components:{
    ContactForm,
    Spacer
  },
  metaInfo: {
    title: "Contact Us | Wellsville Soccer - WHS Tiger Athletics in Wellsville, Ohio",
    meta: [
      { name: "author", content: "Github: @TheKicker" },
      {
        name: "description",
        content:
          "Send us a message.  Thank you for contacting us, we appreciate your interest in the Wellsville High School Soccer team and will respond as soon as we can.",
      },
      {
        name: "keywords",
        content:
          "Wellsville, Ohio, WHS, Wellsville Tigers, OH, High School, HS, Tigers, Athletics, Soccer, Futbol, Football, Boys, Girls, Co-ed, OHSAA, OVAC, EOAC",
      },
      // OpenGraph data (Most widely used)
      { property: "og:title", content: "Contact Us | Wellsville Soccer - WHS Tiger Athletics in Wellsville, Ohio" },
      { property: "og:site_name", content: "Contact Us | Wellsville Soccer - WHS Tiger Athletics in Wellsville, Ohio" },
      // The list of types is available here: http://ogp.me/#types
      { property: "og:type", content: "website" },
      // Should the the same as your canonical link, see below.
      {
        property: "og:url",
        content: "https://wellsvillesoccer.com/contact",
      },
      {
        property: "og:image",
        content: "https://wellsvillesoccer.com/wellsville-soccer-ogp.png",
      },
      // Often the same as your meta description, but not always.
      {
        property: "og:description",
        content:
          "Send us a message.  Thank you for contacting us, we appreciate your interest in the Wellsville High School Soccer team and will respond as soon as we can.",
      },
      // Twitter card
      { name: "twitter:card", content: "summary" },
      {
        name: "twitter:site",
        content: "https://wellsvillesoccer.com/contact",
      },
      { name: "twitter:title", content: "Contact Us | Wellsville Soccer - WHS Tiger Athletics in Wellsville, Ohio" },
      {
        name: "twitter:description",
        content:
          "Send us a message.  Thank you for contacting us, we appreciate your interest in the Wellsville High School Soccer team and will respond as soon as we can.",
      },
      // Your twitter handle, if you have one.
      { name: "twitter:creator", content: "Github: @TheKicker" },
      {
        name: "twitter:image:src",
        content: "https://wellsvillesoccer.com/wellsville-soccer-ogp.png",
      },
      // Google / Schema.org markup:
      { itemprop: "name", content: "Contact Us | Wellsville Soccer - WHS Tiger Athletics in Wellsville, Ohio" },
      {
        itemprop: "description",
        content:
          "Send us a message.  Thank you for contacting us, we appreciate your interest in the Wellsville High School Soccer team and will respond as soon as we can.",
      },
      {
        itemprop: "image",
        content: "https://wellsvillesoccer.com/wellsville-soccer-ogp.png",
      },
    ],
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>